<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        id="dropdown-grouped"
        variant="link"
        class="dropdown-language"
        right
      >
        <template #button-content>
          <b-img
            :src="currentLocale.img"
            height="14px"
            width="22px"
            :alt="currentLocale.locale"
          />
          <span class="ml-50 text-body">{{ $t(currentLocale.name) }}</span>
        </template>
        <b-dropdown-item
          v-for="localeObj in locales"
          :key="localeObj.locale"
          @click="$i18n.locale = localeObj.locale"
        >
          <b-img
            :src="localeObj.img"
            height="14px"
            width="22px"
            :alt="localeObj.locale"
          />
          <span class="ml-50">{{ $t(localeObj.name) }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>

      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ user.name }}
            </p>
            <span class="user-status">{{ user.company_name }}</span>
          </div>

          <span
            class="b-avatar badge-minimal badge-light-primary rounded-circle"
            style="width: 40px; height: 40px"
            ><span class="b-avatar-img">
              <b-avatar variant="primary"></b-avatar></span
            ><span
              class="b-avatar-badge badge-success"
              style="font-size: calc(11.2px); bottom: 0px; right: 0px"
            ></span
          ></span>
          <!-- <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/13-small.png')"
            class="badge-minimal"
            badge-variant="success"
          /> -->
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout()"
        >
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>{{ $t("logout") }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
      <!-- <b-link
        v-if="language === 'de'"
        class="nav-link"
        @click="toggleLanguage('en')"
      >
        <img src="/icons/de.png" class="flag-icon" />
      </b-link>
      <b-link
        v-if="language === 'en'"
        class="nav-link"
        @click="toggleLanguage('de')"
      >
        <img src="/icons/en.png" class="flag-icon" />
      </b-link> -->
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      user: this.$store.state.auth.user,
      language: this.$i18n.locale ? this.$i18n.locale : "en",
      locales: [
        {
          locale: "en",
          img: "/images/flags/en.png",
          name: "english",
        },

        {
          locale: "de",
          img: "/images/flags/german.png",
          name: "german",
        },
      ],
    };
  },
  computed: {
  currentLocale() {
      let obj = null;
      if (this.$i18n.locale) {
        if (this.$i18n.locale == "en") {
          obj = {
            locale: "en",
            img: "/images/flags/en.png",
            name: 'english',
          };
        } else {
          obj = {
            locale: "de",
            img: "/images/flags/german.png",
            name:'german',
          };
        }
      } else {
        obj = {
          locale: "en",
          img: "/images/flags/en.png",
          name: 'english',
        };
      }
      this.$store.commit("app/UPDATE_LANGUAGE", obj.locale);
      return obj;
    },
  },
  methods: {
    toggleLanguage(language) {
      this.$i18n.locale = language;
      this.language = language;
      this.$store.commit("app/UPDATE_LANGUAGE", language);
    },
    logout() {
      this.$store.commit("auth/SET_USER", []);
      this.$store.commit("auth/SET_AUTHENTICATED", false);
      this.$store.commit("auth/UPDATE_TOKEN", null);
      window.location.href = "/login";
    },
  },
};
</script>
<style>
.flag-icon {
  width: 30px;
}
</style>

