export default [
  {
    title: 'dashboard',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'To Do',
    route: 'to-do',
    icon: 'BellIcon',
    count: 0,
  },
  {
    title: 'companies',
    route: 'companies',
    icon: 'CodesandboxIcon',
  },
  {
    title: 'invoices',
    route: 'invoices',
    icon: 'FileTextIcon',
  },
  {
    title: 'documents',
    route: 'documents',
    icon: 'FileIcon',
  },
  {
    title: 'users',
    route: 'users',
    icon: 'UsersIcon',
  },
  {
    title: 'investors',
    route: 'investors',
    icon: 'TrendingUpIcon',
  },
  {
    title: 'languages',
    route: 'languages',
    icon: 'GlobeIcon',
  },
  {
    title: 'settings',
    route: 'settings',
    icon: 'SettingsIcon',
  },
  {
    title: 'scoring_settings',
    route: 'scoring_settings',
    icon: 'SettingsIcon',
  },
  {
    title: 'zoho_leads',
    route: 'zoho_leads',
    icon: 'CodesandboxIcon',
  },
  {
    title: 'Companies ToDo',
    route: 'todo',
    icon: '',
  },
  {
    title: 'IP Logins',
    route: 'ip-logins',
    icon: 'UserIcon',
  },
  {
    title: 'partners',
    route: 'partners',
    icon: 'CodeIcon',
  },
  {
    title: 'Transaction Report',
    route: 'transaction_report',
    icon: 'BarChart2Icon',
  },
  {
    title: 'email_testing',
    route: 'email_testing',
    icon: 'MailIcon',
  },
  {
    title: 'debtor_ips',
    route: 'debtor_ips',
    icon: 'CompassIcon',
  },
  {
    title: 'failed_emails',
    route: 'failed_emails',
    icon: 'ThumbsDownIcon',
  },

  {
    title: 'send_email',
    route: 'send_email',
    icon: 'MailIcon',
  },

  {
    title: 'currency',
    route: 'currency',
    icon: 'DollarSignIcon',
  },

  {
    title: 'transaction_currency',
    route: 'transaction_currency',
    icon: 'CreditCardIcon',
  },
  
]
